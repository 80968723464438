import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageProps } from 'gatsby';
import Image from 'gatsby-image';
import { Card, Col, Container, Row } from 'react-bootstrap';
import './people.css';
import { ContentfulPerson } from '../types/contentful-types';
import { SiteMetadata } from '../types/site-metadata';

type DataProps = {
  site: SiteMetadata;
  allContentfulPerson: {
    edges: [{
      node: ContentfulPerson
    }]
  };
};

const PeoplePage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const people = data.allContentfulPerson.edges.map((person) => {
    const shortBio = { __html: person.node.shortBio.childMarkdownRemark.html };
    return (
      <Link to={person.node.slug}>
        <Col className="p-2">
          <Card className="text-white text-center border-0">
            <Image
              fluid={person.node.images[0].fluid}
              alt={person.node.images[0].title}
              key={person.node.images[0].title}
            />
            <Card.ImgOverlay>
              <Card.Title className="font-weight-bold text-uppercase">{person.node.name}</Card.Title>
              <Card.Text dangerouslySetInnerHTML={shortBio} />
            </Card.ImgOverlay>
          </Card>
        </Col>
      </Link >
    )
  });
  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      <SEO title="People" />
      <Container fluid={true}>
        <Row xs={1} md={2} lg={3}>
          {people}
        </Row>
      </Container>
    </Layout>
  );
};

export default PeoplePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPerson {
      edges {
        node {
          slug
          name
          bio {
            childMarkdownRemark {
              html
            }
          }
          shortBio {
            childMarkdownRemark {
              html
            }
          }
          images {
            description
            title
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
